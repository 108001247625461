@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
body {
  margin: 0;
  font-family: 'Lato', sans-serif;
  padding: 0;
  overflow-x: hidden;
  background: linear-gradient(to left, #558b97, transparent) #554256;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.backgroundgradient{
  background: linear-gradient(to left, #558b97, transparent) #554256;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.hero {
  background: url(./images/hero-bg.svg) top center no-repeat;
  background-size: cover;
}

.footer {
  background: url(./images/footer2.svg) top center no-repeat;
  background-size: cover;
}
.headerBg{
  background: linear-gradient(to left,  #254751,#554256);
}



.cta {
  background: linear-gradient(rgba(2,2,2,.7),rgba(0,0,0,.7)),url(https://images.unsplash.com/photo-1497215728101-856f4ea42174?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80) fixed center center;
}


.thumb,
.thumb::-webkit-slider-thumb {
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
}

.thumb {
    pointer-events: none;
    position: absolute;
    height: 0;
    width: 100%;
    outline: none;
}

.thumb--zindex-3 {
    z-index: 3;
}

.thumb--zindex-4 {
    z-index: 4;
}

.thumb--zindex-5 {
    z-index: 5;
}

/* For Chrome browsers */
.thumb::-webkit-slider-thumb {
    background-color: #f1f5f7 ;
    border: none;

    box-shadow: 0 0 1px 1px #ced4da;
    cursor: pointer;
    height: 14px;
    width: 3px;
    margin-top: 4px;
    pointer-events: all;
    position: relative;
}

/* For Firefox browsers */
.thumb::-moz-range-thumb {
    background-color: #f1f5f7;
    border: none;

    box-shadow: 0 0 1px 1px #ced4da;
    cursor: pointer;
    height: 14px;
    width: 3px;
    margin-top: 4px;
    pointer-events: all;
    position: relative;
}



@font-face {
  font-family: 'rubik';
  src: local('rubik'), url('./font/RubikDoodleShadow-Regular.ttf') format('woff2');
  font-weight: 700; /* Adjust as needed */
  font-style: normal; /* Adjust as needed */
}


.anchor {
  display: block;
  position: relative;
  top: -20px; /* this is your offset */

}